<template>
  <div class="cloud-bg-container">
    <div class="x1">
      <div class="cloud"></div>
    </div>
    <div class="x2">
      <div class="cloud"></div>
    </div>
    <div class="x3">
      <div class="cloud"></div>
    </div>
    <div class="x4">
      <div class="cloud"></div>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
/* KEYFRAMES */
// @-webkit-keyframes animateCloud {
//   0% {
//     margin-right: -100%;
//   }
//   100% {
//     margin-right: 100%;
//   }
//   }
// @-moz-keyframes animateCloud {
//   0% {
//     margin-right: -100%;
//   }
//   100% {
//     margin-right: 100%;
//   }
// }
@keyframes animateCloud {
  0% {
    margin-right: -1000px;
    opacity: 0;
  }
  3% {
    margin-right: -970px;
    opacity: 1;
  }
  90% {
    margin-right: 90%;
    opacity: .7;
  }
  100% {
    margin-right: 100%;
    opacity: 0;
  }
}
/* ANIMATIONS */
/* OBJECTS */
.cloud-bg-container {
  background-color: #162447;
  bottom: 0;
  left: 0;
  padding-top: 75px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;
}
.x1 {
  -webkit-animation: animateCloud 25s ease-in-out infinite;
  -moz-animation: animateCloud 35s ease-in-out infinite;
  animation: animateCloud 35s ease-in-out infinite;
  -webkit-transform: scale(0.65);
  -moz-transform: scale(0.65);
  transform: scale(0.65);
}
.x2 {
  -webkit-animation: animateCloud 18s ease-in-out infinite;
  -moz-animation: animateCloud 20s ease-in-out infinite;
  animation: animateCloud 20s ease-in-out infinite;
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.3);
}
.x3 {
  -webkit-animation: animateCloud 12s ease-in-out infinite;
  -moz-animation: animateCloud 18s ease-in-out infinite;
  animation: animateCloud 18s ease-in-out infinite;
  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  transform: scale(0.4);
}
.x4 {
  -webkit-animation: animateCloud 20s ease-in-out infinite;
  -moz-animation: animateCloud 25s ease-in-out infinite;
  animation: animateCloud 25s ease-in-out infinite;
  -webkit-transform: scale(0.55);
  -moz-transform: scale(0.55);
  transform: scale(0.55);
}
.cloud {
  background: #fff;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  -webkit-box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
  height: 52px;
  position: relative;
  width: 195px;
  transition: .3s;
  &:after {
    background: #fff;
    content: "";
    position: absolute;
    z-index: -1;
    border-radius: 143px;
    height: 70px;
    left: 44px;
    top: -20px;
    width: 70px;
    transition: .3s;
  }
  &:before {
    background: #fff;
    content: "";
    position: absolute;
    z-index: -1;
    border-radius: 200px;
    width: 90px;
    height: 80px;
    right: 25px;
    top: -34px;
    transition: .3s;
  }
}
</style>
